/* You can add global styles to this file, and also import other style files */
@use '/node_modules/@angular/material' as mat;
@import "indigo-pink.css";

// @use '/node_modules/@angular/material/theming';
@import './assets/scss/variables';
@import './assets/scss/maintheme';

@include mat.core();

$whizzimo-orange: #FF8F2C;
$whizzimo-purple: #866DFF;

// //Light Theme
// $whizzimo-primary: mat-palette($md-whizzimo, 500);
// $whizzimo-primary-light: mat-palette($md-whizzimo-light, 100);
// $whizzimo-primary-dark: mat-palette($md-whizzimo-dark, 500);
// $whizzimo-primary-dark-menu: mat-palette($md-whizzimo-dark, 500);
// $whizzimo-primary-dark-background-color: mat-palette($md-whizzimo-light, A400);
// $whizzimo-primary-dark-background: mat-palette($md-whizzimo-light, 500);
// $whizzimo-primary-menu-active-background: mat-palette($md-whizzimo-dark-mode, 900);
// $whizzimo-modal-background-color: mat-palette($md-whizzimo-light, A100);
// $primary-dark-activity-item-child-color: mat-palette($md-whizzimo-dark-mode, 100);
// $whizzimo-mat-tab-label-color: mat-palette($md-whizzimo-dark-mode, 900);
// $whizzimo-primary-form-field-outline-color: mat-palette($md-whizzimo-dark-mode, A800);
// $whizzimo-primary-form-field-color: mat-palette($md-whizzimo-dark-mode, 800);
// $whizzimo-black-white-color: mat-palette($md-whizzimo-dark-mode, A800);
// $whizzimo-font-light-color: mat-palette($md-whizzimo-dark-mode, 100);
// $whizzimo-blackboard-body-color: mat-palette($md-whizzimo-light, 500);
// $whizzimo-input-background-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-border-color: mat-palette($md-whizzimo-dark-mode, 200);
// $whizzimo-light-color: mat-palette($md-whizzimo, A100);
// $whizzimo-white-darkblue-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-black-lightblue-color: mat-palette($md-whizzimo, A100);
// $whizzimo-darkblue-white-color: mat-palette($md-whizzimo-dark-mode, 800);



// $whizzimo-background: mat-palette($mat-gray, 100);
// $whizzimo-warn: mat-palette($mat-deep-orange, 300);

// $whizzimo-theme: mat-light-theme($whizzimo-primary, $whizzimo-primary-dark, $whizzimo-warn);
// $whizzimo-dark-theme: mat-dark-theme($whizzimo-primary, $whizzimo-primary-dark, $whizzimo-warn);

// $primary: mat-color($whizzimo-primary);
// $warn: mat-color($whizzimo-warn, 100);
// $primary-light: mat-color($whizzimo-primary-light);
// $primary-dark: mat-color($whizzimo-primary-dark);
// $primary-dark-menu: mat-color($whizzimo-primary-dark-menu);
// $primary-dark-background-color: mat-color($whizzimo-primary-dark-background-color);
// $primary-dark-background: mat-color($whizzimo-primary-dark-background);
// $whizzimo-menu-item-active: mat-color($whizzimo-primary-menu-active-background);
// $whizzimo-modal-background: mat-color($whizzimo-modal-background-color);
// $primary-dark-activity-item-child: mat-color($primary-dark-activity-item-child-color);
// $whizzimo-mat-tab-label: mat-color($whizzimo-mat-tab-label-color);
// $whizzimo-form-field-outline-color: mat-color($whizzimo-primary-form-field-outline-color);
// $whizzimo-form-field-color: mat-color($whizzimo-primary-form-field-color);
// $whizzimo-black-white: mat-color($whizzimo-black-white-color);
// $whizzimo-font-light: mat-color($whizzimo-font-light-color);
// $whizzimo-blackboard-body: mat-color($whizzimo-blackboard-body-color);
// $whizzimo-input-background: mat-color($whizzimo-input-background-color);
// $whizzimo-border: mat-color($whizzimo-border-color);
// $whizzimo-light: mat-color($whizzimo-light-color);
// $whizzimo-white-darkblue: mat-color($whizzimo-white-darkblue-color);
// $whizzimo-black-lightblue: mat-color($whizzimo-black-lightblue-color);
// $whizzimo-darkblue-white: mat-color($whizzimo-darkblue-white-color);



// $background: mat-color($whizzimo-background);
// $transparent-66: rgba($primary, 0.66);
// $transparent-33: rgba($primary, 0.33);
// $whizzimo-activity-item-child: rgba($primary-dark-activity-item-child, 0.87);
// $whizzimo-form-field-outline: rgba($whizzimo-form-field-outline-color, 0.12);
// $whizzimo-input-background-transparent: rgba($whizzimo-input-background, 1);
// $whizzimo-border-transparent: rgba($whizzimo-border, 1);



//Dark Theme
// $whizzimo-primary: mat-palette($md-whizzimo, 500);
// $whizzimo-primary-light: mat-palette($md-whizzimo-light, 100);
// $whizzimo-primary-dark: mat-palette($md-whizzimo-light, A100);
// $whizzimo-primary-dark-menu: mat-palette($md-whizzimo-dark-mode, A700);
// $whizzimo-primary-dark-background-color: mat-palette($md-whizzimo-dark-mode, A400);
// $whizzimo-primary-dark-background: mat-palette($md-whizzimo-dark-mode, A200);
// $whizzimo-modal-background-color: mat-palette($md-whizzimo-dark-mode, A700);
// $primary-dark-activity-item-child: mat-palette($md-whizzimo-light, A100);
// $primary-dark-activity-item-child-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-mat-tab-label-color: mat-palette($md-whizzimo, 500);
// $whizzimo-primary-form-field-outline-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-primary-form-field-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-black-white-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-font-light-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-blackboard-body-color: mat-palette($md-whizzimo-dark-mode, A200);
// $whizzimo-input-background-color: mat-palette($md-whizzimo-light, A100);
// $whizzimo-border-color: mat-palette($md-whizzimo-dark-mode, 200);
// $whizzimo-light-color: mat-palette($md-whizzimo, 500);
// $whizzimo-white-darkblue-color: mat-palette($md-whizzimo-dark-mode, A400);
// $whizzimo-black-lightblue-color: mat-palette($md-whizzimo, 500);
// $whizzimo-darkblue-white-color: mat-palette($md-whizzimo, A100);


// $whizzimo-background: mat-palette($mat-gray, 100);
// $whizzimo-warn: mat-palette($mat-deep-orange, 300);

// $whizzimo-theme: mat-light-theme($whizzimo-primary, $whizzimo-primary-dark, $whizzimo-warn);
// $whizzimo-dark-theme: mat-dark-theme($whizzimo-primary, $whizzimo-primary-dark, $whizzimo-warn);

// $primary: mat-color($whizzimo-primary);
// $warn: mat-color($whizzimo-warn, 100);
// $primary-light: mat-color($whizzimo-primary-light);
// $primary-dark: mat-color($whizzimo-primary-dark);
// $primary-dark-menu: mat-color($whizzimo-primary-dark-menu);
// $primary-dark-background-color: mat-color($whizzimo-primary-dark-background-color);
// $primary-dark-background: mat-color($whizzimo-primary-dark-background);
// $whizzimo-menu-item-active: mat-color($whizzimo-primary-dark-background);
// $whizzimo-modal-background: mat-color($whizzimo-modal-background-color);
// $primary-dark-activity-item-child: mat-color($primary-dark-activity-item-child-color);
// $whizzimo-mat-tab-label: mat-color($whizzimo-mat-tab-label-color);
// $whizzimo-form-field-outline-color: mat-color($whizzimo-primary-form-field-outline-color);
// $whizzimo-form-field-color: mat-color($whizzimo-primary-form-field-color);
// $whizzimo-black-white: mat-color($whizzimo-black-white-color);
// $whizzimo-font-light: mat-color($whizzimo-font-light-color);
// $whizzimo-blackboard-body: mat-color($whizzimo-blackboard-body-color);
// $whizzimo-input-background: mat-color($whizzimo-input-background-color);
// $whizzimo-border: mat-color($whizzimo-border-color);
// $whizzimo-light: mat-color($whizzimo-light-color);
// $whizzimo-white-darkblue: mat-color($whizzimo-white-darkblue-color);
// $whizzimo-black-lightblue: mat-color($whizzimo-black-lightblue-color);
// $whizzimo-darkblue-white: mat-color($whizzimo-darkblue-white-color);



// $background: mat-color($whizzimo-background);
// $transparent-66: rgba($primary, 0.66);
// $transparent-33: rgba($primary, 0.33);
// $whizzimo-activity-item-child: rgba($primary-dark-activity-item-child, 0.6);
// $whizzimo-form-field-outline: rgba($whizzimo-form-field-outline-color, 0.12);
// $whizzimo-input-background-transparent: rgba($whizzimo-input-background, 0.0);
// $whizzimo-border-transparent: rgba($whizzimo-border, 0.12);


// @include angular-material-theme($whizzimo-theme);



.card {
  float: left;
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
  .quickstart {
    width: 150px;
    float: right;
  }

}

.long-card {
  margin: 20px;
  padding: 10px;
  background-color: #e6e9eb;
  .quickstart {
    width: 150px;
    float: right;
  }

}
.title {
  font-size: 14px !important;
}
.card-title {
  font-weight: 700;
  padding: 5px;
  color: #45506d;

}
.card-sub-title {
  color: $whizzimo-orange;
}
.sub-text {
  font-size: 1em;
  color: #717171;
}
.card-footer {
  padding: 10px;
}
.centered {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
                                      //--- tile styling ---//
.tile {
  width: 8vh;
  height: 8vh;
  // padding: 10px;
  // margin: 5px;
  font-size: 0.8em;
  float: left;
}
.grid-tile {
  width: 8vw;
  height: 8vh;

  // margin: 5vw;
}

.new-workbook {
  height: 150px;
  width: 223px;
  margin: 10px;
  padding: 16px;
  color: #666;
  border: 3px #666 dashed;
  border-radius: 5px;
  cursor: pointer;
}

.workbooks-code-circle {
  width: 22px;
  height: 22px;
  border-radius: 100%;
  font-size: 11px;
  font-weight: 800;
  color: #666;
  border: 1px solid #bbb;
  line-height: 20px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  margin-right: 2px;
}

.workbook-card-content-wrapper {
  position: absolute;
  height: 100%;
}

.workbook-card-settings {
  position: relative;
  height: 30px;
  width: 30px;
  padding: 4px 6px;
  margin: 5px;
  border-radius: 50%;
  border: 1px solid #CCC;
  bottom: 20px;
  left: 55%;
}

.workbooks-code-wrapper {
  position: absolute;
  bottom: 18px;
  left: 5px;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-line {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.menu-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.menu-btn {
  color: #888888;
  margin-bottom: 10px;
}



.btn-cancel {
  background-color: lightgrey;
  font-size: smaller;
  font-weight: bold;
  color: black;
      width: 100%;
}

.words-spinner {
  position: absolute;
  color: #485473;
  padding: 30%;
  margin-top: 100%;
}

.selectable-tile {
  margin: 5px;
  .mat-menu-item {
    // color: $whizzimo-black-white !important;
  }
}

%item-card {
  margin: 10px;
  position: relative;
}

.word-group-item-card-content {
  padding: 10px;
  font-size: larger;
  font-weight: bold;
  margin-bottom: 0 !important;
}



/*= All Possible Tile Colors
========================================*/
.c-white {
  background: white;
}

.c-red {
  background: #d34755; /* Old browsers */
  background: -moz-linear-gradient(top,  #d34755 0%, #ab1f2c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#d34755), color-stop(100%,#ab1f2c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #d34755 0%,#ab1f2c 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #d34755 0%,#ab1f2c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d34755', endColorstr='#ab1f2c',GradientType=0 ); /* IE6-9 */
}

.c-yellow {
  background: #ffd456; /* Old browsers */
  background: -moz-linear-gradient(top, #ffd456 0%, #ffc600 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffd456), color-stop(100%,#ffc600)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffd456 0%,#ffc600 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffd456 0%,#ffc600 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd456', endColorstr='#ffc600',GradientType=0 ); /* IE6-9 */
}

.c-lightyellow {
  background: rgb(255,237,177); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255,237,177,1) 0%, rgba(255,225,130,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,237,177,1)), color-stop(100%,rgba(255,225,130,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255,237,177,1) 0%,rgba(255,225,130,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255,237,177,1) 0%,rgba(255,225,130,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255,237,177,1) 0%,rgba(255,225,130,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(255,237,177,1) 0%,rgba(255,225,130,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffedb1', endColorstr='#ffe182',GradientType=0 ); /* IE6-9 */
}

.c-green {
  background: #48ad5e; /* Old browsers */
  background: -moz-linear-gradient(top,  #48ad5e 0%, #2c8b3c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#48ad5e), color-stop(100%,#2c8b3c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #48ad5e 0%,#2c8b3c 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #48ad5e 0%,#2c8b3c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#48ad5e', endColorstr='#2c8b3c',GradientType=0 ); /* IE6-9 */
}

.c-lightgreen {
  background: rgb(206,230,156); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(206,230,156,1) 0%, rgba(193,229,114,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(206,230,156,1)), color-stop(100%,rgba(193,229,114,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(206,230,156,1) 0%,rgba(193,229,114,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(206,230,156,1) 0%,rgba(193,229,114,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(206,230,156,1) 0%,rgba(193,229,114,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(206,230,156,1) 0%,rgba(193,229,114,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cee69c', endColorstr='#c1e572',GradientType=0 ); /* IE6-9 */
}

.c-blue {
  background: #4c72aa; /* Old browsers */
  background: -moz-linear-gradient(top,  #4c72aa 0%, #1f57ab 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#4c72aa), color-stop(100%,#1f57ab)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #4c72aa 0%,#1f57ab 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #4c72aa 0%,#1f57ab 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c72aa', endColorstr='#1f57ab',GradientType=0 ); /* IE6-9 */
}

.c-lightblue {
  background: rgb(156,196,229); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(156,196,229,1) 0%, rgba(129,183,226,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(156,196,229,1)), color-stop(100%,rgba(129,183,226,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(156,196,229,1) 0%,rgba(129,183,226,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(156,196,229,1) 0%,rgba(129,183,226,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(156,196,229,1) 0%,rgba(129,183,226,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(156,196,229,1) 0%,rgba(129,183,226,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9cc4e5', endColorstr='#81b7e2',GradientType=0 ); /* IE6-9 */
}

.c-peach {
  background: rgb(255,209,196); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(255,209,196,1) 0%, rgba(255,179,158,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,209,196,1)), color-stop(100%,rgba(255,179,158,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255,209,196,1) 0%,rgba(255,179,158,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255,209,196,1) 0%,rgba(255,179,158,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255,209,196,1) 0%,rgba(255,179,158,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(255,209,196,1) 0%,rgba(255,179,158,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd1c4', endColorstr='#ffb39e',GradientType=0 ); /* IE6-9 */
}

.c-purple {
  background: #696493; /* Old browsers */
  background: -moz-linear-gradient(top,  #696493 0%, #504795 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#696493), color-stop(100%,#504795)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #696493 0%,#504795 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #696493 0%,#504795 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #696493 0%,#504795 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #696493 0%,#504795 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#696493', endColorstr='#504795',GradientType=0 ); /* IE6-9 */
}

.c-lightpurple {
  background: rgb(239,196,255); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(239,196,255,1) 0%, rgba(228,160,255,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(239,196,255,1)), color-stop(100%,rgba(228,160,255,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(239,196,255,1) 0%,rgba(228,160,255,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(239,196,255,1) 0%,rgba(228,160,255,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(239,196,255,1) 0%,rgba(228,160,255,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(239,196,255,1) 0%,rgba(228,160,255,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efc4ff', endColorstr='#e4a0ff',GradientType=0 ); /* IE6-9 */
}

.c-orange {
  background: #ff9951; /* Old browsers */
  background: -moz-linear-gradient(top,  #ff9951 0%, #ff872c 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ff9951), color-stop(100%,#ff872c)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #ff9951 0%,#ff872c 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #ff9951 0%,#ff872c 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #ff9951 0%,#ff872c 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #ff9951 0%,#ff872c 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9951', endColorstr='#ff872c',GradientType=0 ); /* IE6-9 */
}

.c-gray {
  background: #898881; /* Old browsers */
  background: -moz-linear-gradient(top,  #898881 0%, #6e6d67 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#898881), color-stop(100%,#6e6d67)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #898881 0%,#6e6d67 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #898881 0%,#6e6d67 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #898881 0%,#6e6d67 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #898881 0%,#6e6d67 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#898881', endColorstr='#6e6d67',GradientType=0 ); /* IE6-9 */
}

.c-cream {
  background: #f4f1de; /* Old browsers */
  background: -moz-linear-gradient(top,  #f4f1de 0%, #e1dec9 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f4f1de), color-stop(100%,#e1dec9)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  #f4f1de 0%,#e1dec9 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  #f4f1de 0%,#e1dec9 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  #f4f1de 0%,#e1dec9 100%); /* IE10+ */
  background: linear-gradient(to bottom,  #f4f1de 0%,#e1dec9 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f1de', endColorstr='#e1dec9',GradientType=0 ); /* IE6-9 */
}

.c-pink {
  background: rgb(244,90,206); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(244,90,206,1) 0%, rgba(247,66,202,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(244,90,206,1)), color-stop(100%,rgba(247,66,202,1))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(244,90,206,1) 0%,rgba(247,66,202,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(244,90,206,1) 0%,rgba(247,66,202,1) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(244,90,206,1) 0%,rgba(247,66,202,1) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(244,90,206,1) 0%,rgba(247,66,202,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f45ace', endColorstr='#f742ca',GradientType=0 ); /* IE6-9 */
}

.c-transparent {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: none;
}

.c-clear {
  background: rgba(0, 0, 0, 0);
  box-shadow: none !important;
  border: none !important;
  /*display: none;*/
  visibility: hidden;
}

.header-tab-text {
  font-size: larger;
  margin-left: 20px
}

.cursor-pointer {
  cursor: pointer;
}

/*= All Possible Tile Font Colors
========================================*/

.fc-black {
  color: #1b1b1b !important;
}

.fc-gray {
  color: #898881 !important;
}

.fc-white {
  color: #ffffff !important;
}

.fc-red {
  color: #EC1414 !important;
}

.tile-query-window {
  background-color: white;
  position: relative;
  z-index: 2;
  min-width: 25%;
  max-width: 75%;
  border: 2px #485473 solid;
  opacity: .9;
  border-radius: 5px;
}

%whizzi-button {
  border-radius: 10px;
  color: white;
  background-color: $whizzimo-orange;
  margin-right: 20px !important;
  cursor: pointer;
}

.whizzi-button-large {
  @extend %whizzi-button;
  padding: 10px 40px;
  font-size: 25px;
}

.whizzi-button-medium {
  @extend %whizzi-button;
  padding: 15px 20px;
  font-size: 15px;
}

.whizzi-button-small {
  @extend %whizzi-button;
  padding: 5px 10px;
  font-size: 15px;
}

%file-hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  opacity: .7;
  border-radius: 0 0 4px 4px;
  color: white;
}

.file-name-container {
  @extend %file-hover;
  overflow-wrap: break-word;
  max-width: 100%;
  color: white;
  padding: 5px
}

.file-options {
  @extend %file-hover;
  visibility: hidden;
  height: 100%;
  text-align: center;
}

%file-option-base {
  padding: 10px;
  cursor: pointer;
}

.file-option {
  @extend %file-option-base;
  height: 50%;
}

.file-option-workbook {
  @extend %file-option-base;
  height: 100%;
  padding-top: 50%;
}

.word-group-item-card {
  @extend %item-card
}

.file-group-card {
  @extend %item-card
}

.file-group-card:hover .file-options {
  visibility: visible;
}

.file-group-card:hover .file-name-container {
  visibility: hidden;
}


mat-grid-list {
  border: white 1px solid;
}

.settings-dropdown {
  padding-top: 10px;
  &:not(:first-child) {
    padding-top: 32px;
  }
  padding-right: 35px;
}

.mat-card .style-card {
  padding: 0 !important;
}

// .style-card-header {
//   width: 100%;
//   height: 70px;
//   background-color: #42495E;
//   color: white;
//   font-weight: bold;
//   padding: 10px 30px 10px 30px;
//   word-wrap: break-word;
// }

.style-card-type {
  margin: 50px;
  padding: 20px;
  //background-color: orange;
  font-size: larger;
  text-align: center;
  font-weight: bolder;
  border-radius: 5px;
  border: black solid 2px
}

.account-detail-label {
  font-weight: bold;
  margin-bottom: 10px
}

.account-detail-text {
  font-weight: normal;
  margin-left: 10px;
}




.plan-price-container {
  text-align: center;
  margin-bottom: 25px;
}

.plan-price-text {
  font-size: xx-large
}

.price-plan-detail-container {
  text-align: center;
  margin-bottom: 15px
}

.price-plan-details-text {
  font-size: 15px;
  color: #97A5AE;
  font-weight: 500;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.activity-canvas-disabled {
  pointer-events: none;
}

.activity-canvas-draw {
  cursor: url("/assets/svgs/regular/draw-cursor.svg"), auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/draw-cursor-white.svg"), auto;

  }
}

.activity-canvas-eraser {
  cursor: url("/assets/svgs/regular/eraser-cursor.svg") 10 10, auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/eraser-cursor-white.svg") 10 10, auto;
  }
}

.activity-canvas-text-64 {
  cursor: url("/assets/svgs/regular/text-cursor-64px.svg") 32 59, auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/text-cursor-64px-white.svg") 32 59, auto;
  }
}
.activity-canvas-text-48 {
  cursor: url("/assets/svgs/regular/text-cursor-48px.svg") 24 44, auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/text-cursor-48px-white.svg") 24 44, auto;
  }
}
.activity-canvas-text-40 {
  cursor: url("/assets/svgs/regular/text-cursor-40px.svg") 20 36, auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/text-cursor-40px-white.svg") 20 36, auto;
  }
}
.activity-canvas-text-32 {
  cursor: url("/assets/svgs/regular/text-cursor-32px.svg") 16 28, auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/text-cursor-32px-white.svg") 16 28, auto;
  }
}
.activity-canvas-text-24 {
  cursor: url("/assets/svgs/regular/text-cursor-24px.svg") 12 22, auto;
  &.dark-theme {
    cursor: url("/assets/svgs/regular/text-cursor-24px-white.svg") 12 22, auto;
  }
}

.mat-list-item-disabled.activity-menu-item {
  cursor: not-allowed !important;
  .mat-list-text > span {
    color: lightgray !important;
  }
  &.dark-mode {
    opacity: 40% !important;
  }
}

.canvas-container {
  canvas {
    border-color: transparent !important;
  }
}

.canvas-pad-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.44);
}

.workbook-activity-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .activity-header {
    z-index: 21;
    .header_activity_top {
      position: relative;
    }
  }
  #canvas-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .pad-canvas {
      transition-duration: 0.5s;
      background: rgba(255, 255, 255, 0.15);
      position: absolute;
      z-index: 11;
      opacity: 0;
    }
    .main-canvas-container, .main-canvas {
      position: absolute;
      z-index: 1;
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  #tileBank {
    height: 400px;
    min-height: 100px;
    overflow: hidden;
    table {
      margin: auto;
    }
  }

  .blank_tile {
    margin-top: 0;
    height: unset;
    table {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .ng-resizable-handle {
    bottom: 0% !important;
    left: 96% !important;
  }

  .tiles-container {
    height: 100%;
    background: white;
    padding-bottom: 16px;
  }

  .new-tile-container {
    overflow: auto;
    height: 100%;
    padding: 16px 0;
  }
}

.blackboard-activity-container {
  #tileDropzone {
    position: relative;
    top: -16px;
  }
  #canvas-container {
    background: white;
  }
}

.workbook-tiles-activity-container {
  #tileDropzone {
    position: relative;
    top: -16px;
    height: calc(100vh - 125px);
  }
  .workbook_tile_div {
    height: unset !important;
    margin-top: 0 !important;
  }
  .workbook-container .textbox-target {
    height: 100vh !important;
  }
}

.wordcards-activity-container {
  .textbox-target {
    padding-top: 16px;
  }

  .activity-header {
    z-index: 200;
  }

  #canvas-container .pad-canvas {
    z-index: 100 !important;
  }
}

.pdf-viewer-activity-container {
  .pdf-container {
    margin-top: 0 !important;
    padding-top: 32px;
    position: absolute;
  }
}

textarea[data-fabric-hiddentextarea] {
  // border: 0px !important;
  // padding: 0px !important;
  // height: 0px !important;
  position: fixed !important;
}

mat-slider {
  .mat-slider-ticks-container {
    background: #8e70ff;
  }

  .mat-slider-thumb {
    border-color: #8e70ff;
    background: #8e70ff;
  }
}

.bg-black {
  background: #000 !important;
}

.bg-white {
  background: #fff !important;
}

.line-warning-message {
  font-size: 12px;
  color: #ff5a5a;
  text-align: end;
}

// // CUSTOM SELECT FIELD
mat-form-field.wz-select {
  all: initial;
  .mat-form-field-label {
    top: 33px;
    left: 16px;
    color: $primary-dark;
    font-size: 16px;
    width: auto !important;
  }

  &.mat-form-field-should-float {
    .mat-form-field-label {
      font-size: 13px;
      background-color: $primary-dark-background-color;
      padding: 5px 10px;
      width: auto;
      top: 21px;
      left: 16px;
    }
  }
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: $whizzimo-purple !important;
}

.dark-theme {
  .mat-mdc-dialog-surface {
    background-color: #0b041e !important;
  }
  mat-label, .mdc-radio__outer-circle, .mdc-radio__inner-circle, .mdc-text-field__input, .mat-mdc-dialog-content {
    color: rgba(255, 255, 255, 0.6) !important;
    border-color: rgba(255, 255, 255, 0.6) !important;
  }

  // dropdown select field
  .mat-select-value-text, .mat-mdc-select-value-text, .mdc-form-field {
    color: white !important;
    border-color: white !important;
  }

  .words_tile_box .mat-button-wrapper {
    color: white !important;
  }
}

.ripple-btn-disabled {
  .mdc-button__ripple {
    display: none !important;
  }
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  border-color: currentColor;

  .dark-theme & {
    border-color: #e0e0e0 !important;
  }
}

.cdk-global-overlay-wrapper:has(mat-dialog-container.mdc-dialog--closing) {
  display: none;
}

mat-radio-button.setting_radio {
    .mdc-label {
        color: #210854;
        font-size: 16px;
        .dark-theme & {
          color: white !important;
        }
    }
}

.add-credit-card-modalbox .mat-mdc-dialog-container {
  padding: 24px;
  .mat-mdc-form-field {
    padding-top: 10px;
  }
  .card-name {
    padding-top: 0;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.logout-modal {
    mat-dialog-actions {
      margin-right: 20px;
      margin-bottom: 8px;
    }
    mat-dialog-content {
      padding-bottom: 0 !important;
    }
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  display: none;
}

.mdc-button__ripple, .mdc-checkbox__ripple {
  display: none;
}

[contenteditable=true] {
  padding: 4px;
  display: block;
}

[contenteditable=true]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  opacity: 0.5
}

.mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
  body:not(.dark-theme) & {
    border-color: #481e9f !important;
  }
  body.dark-theme & {
    border-color: white !important;
  }
}

.mat-mdc-checkbox .mdc-checkbox__background {
  .dark-theme & {
    border-color: rgba(255, 255, 255, 0.12) !important
  }
}

.account-modal button.add-new-card-btn {
  .dark-theme & {
    border-color: rgba(255, 255, 255, 0.12) !important;
  }
}

.mat-mdc-dialog-container {
  .dark-theme & {
    .mdc-dialog__title {
      color: white !important
    }
  }
}

.mdc-list-item:hover .mdc-list-item__primary-text {
  .dark-theme & {
    color: white;
  }
}

.mat-mdc-paginator {
  .dark-theme & {
    background: #18112f;
    .mat-mdc-paginator-range-label {
      color: white;
    }
    .mdc-icon-button svg {
      fill: #866dff;
    }
    .mdc-icon-button:disabled svg {
      fill: gray;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: 20px !important;
}

.mdc-list-item__primary-text {
  .dark-theme & {
    color: white;
  }
}

.mdc-checkbox__background {
  .dark-theme & {
    border-color: white !important;
  }
}

.letter-card-dialog {
  .mdc-dialog .mdc-dialog__content {
    padding: 0 !important;
  }
}

.purple-checkbox {
  padding-bottom: 8px !important;
  mat-list-option {
    padding: 0 16px 0 8px !important;
  }
  .delete-btn {
    padding-left: 16px !important;
    .mdc-button__label {
      display: flex !important;
    }
  }
  .mdc-list-item__end {
    margin-left: 0 !important;
  }
}

.collection-name {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: gray;
      border-width: 1px;
      opacity: 1;
    }
  }
}

.workbook-builder-tab {
  .mat-mdc-tab-body-content {
    height: calc(100% - 58px);
  }
}